import useTranslation from 'next-translate/useTranslation'
import singletonRouter from 'next/router'
import { ProductStocks } from '@utils/stocks'
import algoliasearch from 'algoliasearch/lite'
import aa from 'search-insights'
import { COUNTRY_WITH_REPLICA } from '@constants'
import { getCookie } from '@utils/cookies'
import { createInstantSearchRouterNext } from 'react-instantsearch-router-nextjs'

const applicationID = process.env.NEXT_PUBLIC_ALGOLIA_APP_ID || ''
const searchOnlyAPIKey = process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY || ''
const applicationIndex =
  process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_INDEX || 'preprod_'

export const nbCharactersTriggerSearch = 2

export const ages = 'ages'
export const brandName = 'brand.name'
export const closingType = 'closingType'
export const collections = 'collections'
export const discounts = 'discounts.value'
export const discountsPublic = 'discountsPublic.value'
export const discountsAll = 'discountsAll.value'
export const isInStock = 'isInStock'
export const isGreenable = 'isGreenable'
export const isOutlet = 'isOutlet'
export const colorGroup = 'colorGroup'
export const lowestPrice = (deliveryCountryCode) =>
  `lowestPrice.${deliveryCountryCode}.taxIncluded`
export const lowestPriceVP = (deliveryCountryCode) =>
  `lowestPriceVP.${deliveryCountryCode}.taxIncluded`
export const sexs = 'sexs'
export const shoeSizeSizeAndStock = 'declinations.shoeSize.sizeAndStock'
export const sizeSizeAndStock = 'declinations.size.sizeAndStock'
export const shoeSizeGroups = 'declinations.shoeSize.groups'
export const sizeGroups = 'declinations.size.groups'
export const types0 = 'types.lvl0'
export const types1 = 'types.lvl1'
export const types2 = 'types.lvl2'
export const types3 = 'types.lvl3'

export enum ACTION {
  ADDED_TO_CART = 'Product Added to Cart',
  ADD_TO_WISHLIST = 'Product Added to Wishlist',
  AVAILABILITY_ALERT = 'Clicked on Availability Alert',
}

export enum FROM {
  SEARCH_BAR = 'searchBar',
  DETAIL_PRODUCT = 'detailProduct',
  LISTING_PRODUCT = 'listingProduct',
}

export interface SizeAndStock {
  size: string
  isInStock: boolean
}
export interface SizeRefinement extends SizeAndStock {
  value: string
  label: string
  highlighted?: string
  count: number
  isRefined: boolean
}

export const filterExcludedCountries = (deliveryCountryCode) =>
  `NOT excludedCountries:${deliveryCountryCode}`

export const itemToSizeAndStock = (json) => {
  let sizeAndStock: SizeAndStock = { size: json, isInStock: false }
  try {
    sizeAndStock = JSON.parse(json) as SizeAndStock
  } catch (e) {
    console.error(e)
  }
  return sizeAndStock
}

export const getStocks = (hit: HitAlgolia): ProductStocks => ({
  availability: {
    from: hit.availability?.from,
    to: hit.availability?.to,
    onDemand: hit.availability?.onDemand,
  },
  declinations: hit.declinations.reduce((acc, declination) => {
    acc[declination.sku] = {
      quantity: declination.stockQuantity,
    }
    return acc
  }, {}),
  isSalableOutOfStock: hit.isSalableOutOfStock,
})

export const getDeclinations = (hit: HitAlgolia) =>
  hit.declinations.map((declination) => ({
    active: declination.active,
    eans: declination.eans,
    price: declination.price,
    sku: declination.sku,
    size: declination.size ? declination.size.name : declination.shoeSize?.name,
    sizeListing: declination.size
      ? declination.size?.shortName
      : declination.shoeSize?.shortName,
  }))

export const searchClient = algoliasearch(applicationID, searchOnlyAPIKey, {
  headers: {
    referer:
      process.env.NEXT_PUBLIC_SMALLABLE_BASE_URL || 'https://www.smallable.com',
  },
})
export const analyticsTagsAlgolia = ({
  deliveryCountryCode,
  locale,
  uid,
  isMobile,
  from,
}: {
  deliveryCountryCode: string
  locale: string
  uid: string
  isMobile: boolean
  from: string
}) => {
  return [
    `country_${deliveryCountryCode}`,
    `lang_${locale}`,
    isMobile ? 'mobile' : 'desktop',
    uid ? 'connected' : 'not_connected',
    `origin_${from}`,
  ]
}

export const userTokenAlgolia = () => {
  let userToken
  aa('getUserToken', null, (err, newUserToken) => {
    if (err) {
      console.error(err)
      return
    }
    userToken = newUserToken
  })
  return userToken
}

export const initAlgoliaAnalytic = (uid: string) => {
  aa('init', {
    appId: applicationID,
    apiKey: searchOnlyAPIKey,
    useCookie: true,
  })

  if (uid) {
    aa('setUserToken', uid)
  }

  return aa
}

export const tagAlgoliaClickedFilters = ({
  filters,
  deliveryCountryCode,
  locale,
}: {
  filters: string[]
  deliveryCountryCode: string
  locale: string
}) => {
  aa('clickedFilters', {
    userToken: userTokenAlgolia(),
    index: indexNameAlgolia(deliveryCountryCode, locale),
    eventName: 'Clicked Filters',
    filters: filters,
  })
}

export const tagAlgoliaClick = ({
  hit,
  deliveryCountryCode,
  locale,
  from,
}: {
  hit: HitAlgolia
  deliveryCountryCode: string
  locale: string
  from: string
}) => {
  if (hit) {
    if (hit.__queryID) {
      aa('clickedObjectIDsAfterSearch', {
        userToken: userTokenAlgolia(),
        eventName: `${from} : Product Clicked After Search`,
        index: indexNameAlgolia(deliveryCountryCode, locale),
        queryID: hit.__queryID,
        objectIDs: [hit.objectID],
        positions: [hit.__position],
      })
    } else {
      aa('clickedObjectIDs', {
        userToken: userTokenAlgolia(),
        eventName: `${from} : Product Clicked`,
        index: indexNameAlgolia(deliveryCountryCode, locale),
        objectIDs: [hit.objectID],
      })
    }
  }
}

export const indexToSort = (indexname) => {
  if (indexname.includes('price') && indexname.includes('asc'))
    return 'price:asc'
  if (indexname.includes('price') && indexname.includes('desc'))
    return 'price:desc'
  if (indexname.includes('stock')) return 'stock:desc'
  if (indexname.includes('activationDate')) return 'activationDate:desc'
  return 'pertinence'
}

export const getAlgoliaIndexName = (
  deliveryCountryCode: string,
  locale: string,
  sort?: string,
  isVP?: boolean
) => {
  let indexAlgolia = indexNameAlgolia(deliveryCountryCode, locale)
  if (['price:asc', 'price:desc'].includes(sort)) {
    indexAlgolia = priceReplicaName({
      indexName: indexAlgolia,
      deliveryCountryCode,
      isVP: !!isVP,
    })
  }

  switch (sort) {
    case 'activationDate:desc':
      return `${indexAlgolia}_activationDate_desc`
    case 'price:asc':
      return `${indexAlgolia}_asc`
    case 'price:desc':
      return `${indexAlgolia}_desc`
    case 'stock:desc':
      return `${indexAlgolia}_stock_desc`
    default:
      return indexAlgolia
  }
}

export const tagAlgoliaConvertion = ({
  objectId,
  queryID,
  typeAction = ACTION.ADDED_TO_CART,
  from,
  deliveryCountryCode,
  locale,
}: {
  objectId: string
  queryID: string
  from: string
  typeAction?: string
  deliveryCountryCode: string
  locale: string
}) => {
  if (queryID) {
    aa('convertedObjectIDsAfterSearch', {
      userToken: userTokenAlgolia(),
      index: indexNameAlgolia(deliveryCountryCode, locale),
      eventName: `${from} : ${typeAction} After Search`,
      queryID: queryID,
      objectIDs: [objectId],
    })
  } else {
    aa('convertedObjectIDs', {
      userToken: userTokenAlgolia(),
      index: indexNameAlgolia(deliveryCountryCode, locale),
      eventName: `${from} : ${typeAction}`,
      objectIDs: [objectId],
    })
  }
}

export const indexNameSuggestion = (
  deliveryCountryCode: string,
  locale: string
) => `${indexNameAlgolia(deliveryCountryCode, locale)}_query_suggestions`

export const indexNameAlgolia = (
  deliveryCountryCode: string, // Code international
  locale: string // Langue
) => {
  // On souhaite récupérer l'index algolia sous la forme suivante : "products_eu1_fr"
  let zone = 'apac'
  switch (deliveryCountryCode) {
    case 'US':
    case 'CA':
    case 'QA':
    case 'KW':
    case 'SA':
    case 'AE':
      zone = 'amme'
      break
    case 'CN':
    case 'HK':
    case 'SG':
    case 'JP':
    case 'KR':
    case 'AU':
      zone = 'apac'
      break
    case 'AT':
    case 'BE':
    case 'BG':
    case 'HR':
    case 'CY':
    case 'CZ':
    case 'DK':
    case 'EE':
      zone = 'eu1'
      break
    case 'FI':
    case 'FR':
    case 'DE':
    case 'GR':
    case 'HU':
    case 'IE':
    case 'IT':
    case 'LV':
      zone = 'eu2'
      break
    case 'LT':
    case 'LU':
    case 'MT':
    case 'NL':
    case 'PL':
    case 'PT':
    case 'RO':
    case 'SK':
      zone = 'eu3'
      break
    case 'SI':
    case 'ES':
    case 'SE':
    case 'CH':
    case 'GB':
      zone = 'eu4'
      break
    default:
      zone = 'apac'
      break
  }

  // "products_" + ZONE + "_" + local
  return applicationIndex + `products_${zone}_${locale}`
}

export const priceReplicaName = ({
  indexName,
  deliveryCountryCode,
  isVP = false,
}: {
  indexName: string
  deliveryCountryCode: string
  isVP?: boolean
}) =>
  `${indexName}_${isVP ? `price_vp` : 'price'}_${
    COUNTRY_WITH_REPLICA?.includes(deliveryCountryCode)
      ? deliveryCountryCode
      : 'AU'
  }`

export const CeilPrice = (price: number) => {
  return (Math.ceil(price * 2) / 2).toFixed(2)
}

export const hasCookieInHits = (hits) => {
  let cookieName = ''
  for (let i = 0; i < hits?.length; i++) {
    if (hits[i]?.discountsAll?.length) {
      for (let j = 0; j < hits[i]?.discountsAll?.length; j++) {
        if (hits[i]?.discountsAll[j]?.cookieName) {
          cookieName = hits[i]?.discountsAll[j]?.cookieName
        }
      }
    }
  }
  const cookieInBrowser = getCookie(cookieName, document?.cookie) || null
  if (!cookieInBrowser) return false
  return true
}

export const hasCookieInHit = (hit: HitAlgolia) => {
  let cookieName = ''
  for (let i = 0; i < hit?.discountsAll?.length; i++) {
    if (hit?.discountsAll[i]?.cookieName) {
      cookieName = hit?.discountsAll[i]?.cookieName
    }
  }
  const cookieInBrowser =
    typeof document !== 'undefined'
      ? getCookie(cookieName, document?.cookie) || null
      : null
  if (!cookieInBrowser) return false
  return true
}

export const getApiPrices = ({
  hit,
  currencyCode,
  currencyRate = 1,
  deliveryCountryCode,
  locale,
}: {
  hit: HitAlgolia
  currencyCode: string
  currencyRate: number
  deliveryCountryCode: string
  locale: string
}): ApiPrices => {
  let minPriority = Infinity

  const discountToCheck = hasCookieInHit(hit)
    ? hit?.discountsAll
    : hit?.discountsPublic || hit?.discounts

  const discount =
    (discountToCheck?.reduce((acc, cur) => {
      if (
        cur.priority < minPriority &&
        cur.countries.includes(deliveryCountryCode)
      ) {
        minPriority = cur.priority
        return cur
      } else return acc
    }, 0) as Discount) || null
  // On arrondit à l'euro au dessus le prix tax inclue
  const declinationsPrices = hit.declinations.reduce((acc, declination) => {
    // Old prices :
    // "SD": {
    //   "taxExcluded": 31,
    //   "taxIncluded": 31
    // },
    //
    // New prices :
    // "SD": 31

    const priceValue = declination.price?.[deliveryCountryCode]
    const isNewPrice = typeof priceValue === 'number'

    const priceTaxIncluded = priceValue
      ? isNewPrice
        ? Math.ceil(priceValue * currencyRate)
        : Math.ceil(priceValue.taxIncluded * currencyRate)
      : undefined

    acc[declination.sku] = {
      priceTaxIncluded: priceTaxIncluded,
      // On multiplie par le currencyRate, on arrondit à 2 chiffres après la virgule
      discountInfo: discount
        ? {
            priceTaxIncluded: priceTaxIncluded * (1 - discount?.value / 100),
            discount: discount?.value + '%',
            discountName: discount?.name,
            discountType: discount?.type,
          }
        : null,
    }
    return acc
  }, {})

  return {
    countryCode: locale,
    currencyCode: currencyCode,
    declinations: declinationsPrices,
  }
}

export const getSizeSorted = (items): SizeRefinement[] => {
  const { t } = useTranslation()
  const ageSizes = [
    t('block:ages.month'),
    t('block:ages.months'),
    t('block:ages.year'),
    t('block:ages.years'),
  ]
  const babySizes = ['M', 'A', 'Y', 'J']
  const adultSizes = [
    'XXS',
    'XS',
    'XS/S',
    'S',
    'S/M',
    'M',
    'M/L',
    'L',
    'L/XL',
    'XL',
    'XXL',
    '2XL',
    '3XL',
    '4XL',
    'T1',
    'T2',
    'TU',
  ]

  const sortedItems = items.sort((itemA, itemB) => {
    const itemASize = itemA.size?.toString() || itemA.value.toString()
    const itemBSize = itemB.size?.toString() || itemB.value.toString()

    if (!isNaN(itemASize) && !isNaN(itemB.size)) {
      const aNumber = parseFloat(itemASize.replace(',', '.'))
      const bNumber = parseFloat(itemBSize.replace(',', '.'))
      if (aNumber === bNumber) {
        return 0
      }
      return aNumber > bNumber ? 1 : -1
    }
    if (!isNaN(itemASize)) {
      return 1
    }
    if (!isNaN(itemBSize)) {
      return -1
    }

    if (
      ageSizes.some((ageSize) => itemASize.includes(ageSize)) &&
      ageSizes.some((ageSize) => itemBSize.includes(ageSize))
    ) {
      const indexAgeSizeA = ageSizes.indexOf(
        ageSizes.find((size) => itemASize.includes(size))
      )
      const indexAgeSizeB = ageSizes.indexOf(
        ageSizes.find((size) => itemBSize.includes(size))
      )
      if (indexAgeSizeA - indexAgeSizeB === 0) {
        if (parseInt(itemASize) == parseInt(itemBSize)) {
          return itemASize.length - itemBSize.length
        }
        return parseInt(itemASize) - parseInt(itemBSize)
      }

      return indexAgeSizeA > indexAgeSizeB ? 1 : -1
    }

    if (
      adultSizes.some((size) => itemASize === size) &&
      adultSizes.some((size) => itemBSize === size)
    ) {
      return adultSizes.indexOf(itemASize) - adultSizes.indexOf(itemBSize)
    }
    if (adultSizes.some((size) => itemASize === size)) {
      return 1
    }
    if (adultSizes.some((size) => itemBSize === size)) {
      return -1
    }

    if (
      babySizes.some((babySize) => itemASize.includes(babySize)) &&
      babySizes.some((babySize) => itemBSize.includes(babySize))
    ) {
      const indexBabySizeA = babySizes.indexOf(
        babySizes.find((size) => itemASize.includes(size))
      )
      const indexBabySizeB = babySizes.indexOf(
        babySizes.find((size) => itemBSize.includes(size))
      )
      if (indexBabySizeA - indexBabySizeB === 0) {
        if (parseInt(itemASize) == parseInt(itemBSize)) {
          return itemASize.length - itemBSize.length
        }
        return parseInt(itemASize) - parseInt(itemBSize)
      }

      return indexBabySizeA > indexBabySizeB ? 1 : -1
    }

    return itemASize.localeCompare(itemBSize)
  })

  return sortedItems
}

function getSlug(name) {
  return name.split(' ').map(encodeURIComponent).join('-')
}

function getName(slug) {
  return slug.split('-').map(decodeURIComponent).join(' ')
}

function setDashValue(value) {
  let _value = value
  if (_value.startsWith(':')) _value = 'min' + _value
  if (_value.endsWith(':')) _value = _value + 'max'
  return _value.replace(':', '-')
}

function getDashValue(value) {
  let _value = value
  if (_value.startsWith('min')) _value = _value.replace('min', '')
  if (_value.endsWith('max')) _value = _value.replace('max', '')
  return value.replace('-', ':')
}

type RouteState = {
  q: string
  page: number
  _ages: string
  _brand: string[]
  _collection: string[]
  _color: string[]
  _discount: string[]
  _price: string
  _price_vp: string
  _sex: string[]
  _shoe_size: string[]
  _size: string[]
  _shoe_size_group: string[]
  _size_group: string[]
  _typology: string[]
  _in_stock: boolean
  _greenable: boolean
  _outlet: boolean
}

export const routingConfig = (
  deliveryCountryCode: string,
  locale: string,
  sort: string,
  serverUrl?: string,
  isListingType: boolean = false
) => {
  const indexName = getAlgoliaIndexName(deliveryCountryCode, locale, sort)

  return {
    router: createInstantSearchRouterNext({
      singletonRouter,
      serverUrl,
      routerOptions: {
        cleanUrlOnDispose: false,
        createURL: ({ qsModule, routeState, location }) => {
          const baseUrl = location.origin + location.pathname
          const queryParameters: Record<string, any> = {}

          if (routeState.q) {
            if (decodeURIComponent(routeState.q as string) !== routeState.q) {
              queryParameters.q = encodeURIComponent(routeState.q as string)
            } else {
              queryParameters.q = routeState.q
            }
          }
          if (routeState._ages) {
            queryParameters._ages = setDashValue(routeState._ages as string)
          }
          if (routeState.page !== 1) {
            queryParameters.page = routeState.page
          }
          if (routeState._brand) {
            const brands = []
            for (const brand of routeState._brand as string[]) {
              brands.push(brand)
            }
            queryParameters._brand = brands
          }
          if (routeState._collection) {
            queryParameters._collection = routeState._collection as string[]
          }
          if (routeState._color) {
            queryParameters._color = routeState._color as string[]
          }
          if (routeState._discount) {
            const discounts = []
            for (const discount of routeState._discount as string[]) {
              discounts.push(getSlug(discount))
            }
            queryParameters._discount = discounts
          }
          if (routeState._greenable) {
            queryParameters._greenable = routeState._greenable as boolean
          }
          if (routeState._outlet) {
            queryParameters._outlet = routeState._outlet as boolean
          }
          if (routeState._in_stock) {
            queryParameters._in_stock = routeState._in_stock as boolean
          }
          if (routeState._price) {
            queryParameters._price = setDashValue(routeState._price as string)
          }
          if (routeState._price_vp) {
            queryParameters._price_vp = setDashValue(
              routeState._price_vp as string
            )
          }
          if (routeState._sex) {
            queryParameters._sex = routeState._sex as string[]
          }
          if (routeState._closing_type) {
            queryParameters._closing_type = routeState._closing_type as string[]
          }
          if (routeState._shoe_size) {
            const shoeSizes = []
            for (const shoeSize of routeState._shoe_size as string[]) {
              shoeSizes.push(getSlug(shoeSize))
            }
            queryParameters._shoe_size = shoeSizes
          }
          if (routeState._size) {
            const sizes = []
            for (const size of routeState._size as string[]) {
              sizes.push(getSlug(size))
            }
            queryParameters._size = sizes
          }

          if (routeState._shoe_size_group) {
            const shoeSizesGroups = []
            for (const shoeSizeGroup of routeState._shoe_size_group as string[]) {
              shoeSizesGroups.push(getSlug(shoeSizeGroup))
            }
            queryParameters._shoe_size_group = shoeSizesGroups
          }

          if (routeState._size_group) {
            const sizeGroups = []
            for (const sizeGroup of routeState._size_group as string[]) {
              sizeGroups.push(getSlug(sizeGroup))
            }
            queryParameters._size_group = sizeGroups
          }

          if (routeState._typology) {
            queryParameters._typology = routeState._typology as string[]
          }
          if (routeState._sort) {
            queryParameters._sort = routeState._sort
          }
          const queryString = qsModule.stringify(queryParameters, {
            addQueryPrefix: true,
            arrayFormat: 'repeat',
          })

          const url =
            isListingType && serverUrl?.length
              ? serverUrl + queryString
              : baseUrl + queryString

          return url
        },
        parseURL: ({ qsModule, location }): any => {
          const {
            q = '',
            _ages = '',
            _brand = [],
            _closing_type = [],
            _collection = [],
            _color = [],
            _discount = [],
            _greenable = false,
            _outlet = false,
            _in_stock = false,
            _price = '',
            _price_vp = '',
            _sex = [],
            _shoe_size = [],
            _size = [],
            _shoe_size_group = [],
            _size_group = [],
            _typology = [],
            page,
            ...rest
          } = qsModule.parse(location.search.slice(1))
          const allBrands = Array.isArray(_brand)
            ? _brand
            : [_brand].filter(Boolean)

          const allCollections = Array.isArray(_collection)
            ? _collection
            : [_collection].filter(Boolean)

          const allColors = Array.isArray(_color)
            ? _color
            : [_color].filter(Boolean)

          const allDiscounts = Array.isArray(_discount)
            ? _discount
            : [_discount].filter(Boolean)

          const allSex = Array.isArray(_sex) ? _sex : [_sex].filter(Boolean)

          const allClosingType = Array.isArray(_closing_type)
            ? _closing_type
            : [_closing_type].filter(Boolean)

          const allShoeSizes = Array.isArray(_shoe_size)
            ? _shoe_size
            : [_shoe_size].filter(Boolean)

          const allSizes = Array.isArray(_size)
            ? _size
            : [_size].filter(Boolean)

          const allShoeSizeGroups = Array.isArray(_shoe_size_group)
            ? _shoe_size_group
            : [_shoe_size_group].filter(Boolean)

          const allSizeGroups = Array.isArray(_size_group)
            ? _size_group
            : [_size_group].filter(Boolean)

          const allTypology = Array.isArray(_typology)
            ? _typology
            : [_typology].filter(Boolean)

          return {
            ...rest,
            q: decodeURIComponent(q as string),
            page: Number(page) || 1,
            _ages: getDashValue(_ages) as string,
            _brand: allBrands.map((collection) => collection as string),
            _closing_type: allClosingType.map(
              (closingType) => closingType as string
            ),
            _color: allColors.map((collection) => collection as string),
            _collection: allCollections.map(
              (collection) => collection as string
            ),
            _discount: allDiscounts.map((discount) => discount as string),
            _in_stock: _in_stock === 'true',
            _outlet: _outlet === 'true',
            _greenable: _greenable === 'true',
            _price: getDashValue(_price) as string,
            _price_vp: getDashValue(_price_vp) as string,
            _sex: allSex.map((sex) => sex as string),
            _shoe_size: allShoeSizes.map(getName),
            _size: allSizes.map(getName),
            _shoe_size_group: allShoeSizeGroups.map(getName),
            _size_group: allSizeGroups.map(getName),
            _typology: allTypology.map((typology) => typology as string),
          }
        },
      },
    }),

    stateMapping: {
      stateToRoute(uiState) {
        const indexUiState = uiState[indexName]
        // on recupere le tri dans _sort de l'url sinon on le recupere dans sortBy (nom de l'index)
        const _sort = indexUiState._sort
          ? indexUiState._sort
          : indexUiState.sortBy
          ? indexToSort(indexUiState.sortBy)
          : undefined

        return {
          q: indexUiState.query,
          _sort,
          _ages: indexUiState.range?.[ages],
          _brand: indexUiState.refinementList?.[brandName],
          _closing_type: indexUiState.refinementList?.[closingType],
          _collection: indexUiState.refinementList?.[collections],
          _color: indexUiState.refinementList?.[colorGroup],
          _discount: indexUiState.refinementList?.[discountsAll],
          _greenable: indexUiState.toggle?.[isGreenable],
          _in_stock: indexUiState.toggle?.[isInStock],
          _price_vp: indexUiState.range?.[lowestPriceVP(deliveryCountryCode)],
          _outlet: indexUiState.toggle?.[isOutlet],
          _price: indexUiState.range?.[lowestPrice(deliveryCountryCode)],
          _sex: indexUiState.refinementList?.[sexs],
          _shoe_size: indexUiState.refinementList?.[shoeSizeSizeAndStock],
          _size: indexUiState.refinementList?.[sizeSizeAndStock],
          _shoe_size_group: indexUiState.refinementList?.[shoeSizeGroups],
          _size_group: indexUiState.refinementList?.[sizeGroups],
          _typology: indexUiState.hierarchicalMenu?.[types0],
          page: indexUiState.page,
        }
      },
      routeToState(routeState) {
        return {
          [indexName]: {
            query: routeState.q,
            range: {
              [lowestPrice(deliveryCountryCode)]: routeState._price,
              [lowestPriceVP(deliveryCountryCode)]: routeState._price_vp,
              [ages]: routeState._ages,
            },
            refinementList: {
              [brandName]: routeState._brand,
              [closingType]: routeState._closing_type,
              [collections]: routeState._collection,
              [colorGroup]: routeState._color,
              [shoeSizeSizeAndStock]: routeState._shoe_size,
              [sizeSizeAndStock]: routeState._size,
              [shoeSizeGroups]: routeState._shoe_size_group,
              [sizeGroups]: routeState._size_group,
              [discountsAll]: routeState._discount,
              [sexs]: routeState._sex,
            },
            toggle: {
              [isInStock]: routeState._in_stock,
              [isGreenable]: routeState._greenable,
              [isOutlet]: routeState._outlet,
            },
            hierarchicalMenu: {
              [types0]: routeState._typology,
            },
            page: routeState.page,
            _sort: routeState._sort,
          },
        }
      },
    },
  }
}

export const getRecentSearchesFromLocalStorage = () => {
  const recentSearches = localStorage.getItem('ALGOLIA_RECENT_SEARCHES')
  return recentSearches ? JSON.parse(recentSearches) : []
}

export const setRecentSearchesToLocalStorage = (
  search: string,
  isBrand: boolean = false,
  slug?: string
) => {
  const recentSearches = getRecentSearchesFromLocalStorage()
  const searchObj = isBrand
    ? { isBrand, query: search, brandSlug: slug }
    : { query: search }

  for (let i = 0; i < recentSearches.length; i++) {
    if (recentSearches[i].query === searchObj.query) return null
  }

  if (recentSearches?.length >= 5) recentSearches.pop()
  recentSearches?.unshift(searchObj)
  localStorage.setItem(
    'ALGOLIA_RECENT_SEARCHES',
    JSON.stringify(recentSearches)
  )
}

export function itemToBrand(json) {
  let productBrand: ProductBrandSearch
  try {
    productBrand = JSON.parse(json)
  } catch (e) {
    console.error(e)
  }
  return productBrand
}

export const removeTypename = (value) => {
  if (Array.isArray(value)) {
    return value.map((v) => removeTypename(v))
  } else if (value !== null && typeof value === 'object') {
    const newObj = {}
    Object.keys(value).forEach((key) => {
      if (key !== '__typename') {
        newObj[key] = removeTypename(value[key])
      }
    })
    return newObj
  }
  return value
}

export const formatParamsToAlgoliaFilters = ({
  deliveryCountryCode = 'FR',
  parameters,
}: {
  deliveryCountryCode: string
  parameters: Partial<AlgoliaSearchParameters>
}) => {
  let result = `(${filterExcludedCountries(deliveryCountryCode)})`

  for (let key in parameters) {
    if (parameters.hasOwnProperty(key)) {
      let values = parameters[key]
      if (typeof parameters[key] === 'string') {
        values = parameters[key].split('+').join(` OR ${key}:`)
      }
      result += ` AND (${key}:${values})`
    }
  }
  return result
}
